import style from "../../../../styles/cards/WhyCard.module.scss";
import Button from "../../Buttons/Button";

const WhyCard = (props) => {
  return (
    <div className={style.card}>
      <div className={style.cardContent}>
        <h3 className={style.header}>{props.data.title}</h3>
        <p className={style.desc}>{props.data.description}</p>
      </div>
      <div className={style.cardActions}>
        <Button variant="primary" label={props.data.button} fullWidth={true} />
      </div>
    </div>
  );
};

export default WhyCard;
