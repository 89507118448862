import { staticWhyList } from "../../../data";
import sectionStyle from "../../../styles/sections/SectionHeader.module.scss";
import style from "../../../styles/sections/Why.module.scss";
import WhyCard from "../../UI/Cards/WhyCard";

const Why = () => {
  return (
    <section className={`${style.whyWrap} ${sectionStyle.sectionPrimary}`}>
      <div
        className={`${style.whyContainer} container-xl ${sectionStyle.sectionContainer}`}
      >
        <div className={style.content}>
          <div className={sectionStyle.sectionWrap}>
            <h2 className={sectionStyle.title}>Why Carefindr?</h2>
            <p className={sectionStyle.subtitle}>
              Carefindr is a free service that helps families and retirees find
              the UK's best care homes and retirement living communities.
            </p>
          </div>
          <div className={style.list}>
            {staticWhyList.map((card, index) => (
              <WhyCard key={index} data={card} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Why;
